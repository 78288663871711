<template>
  <div class="docs-view__faqs-element">
    <links-list
      :linkListHeading="'FAQs'"
      :listItemIcon="'icon-question'"
      :linkset="getLinkset()"
    ></links-list>
  </div>
</template>
<script>
import LinksList from './LinksList.vue';

export default {
  name: 'FaqsElement',
  components: { LinksList },

  props: ['linkListHeading', 'listItemIcon', 'linkset'],
  methods: {
    getLinkset() {
      return [
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
      ];
    },
  },
};
</script>