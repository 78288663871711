const state = {
    sidebarDisplay: true,
};
const getters = {
    getSidebarDisplay(state) {
        return state.sidebarDisplay;
    },
};
const actions = {
    toggleDocsSidebarDisplay({ commit }, isShown) {
        if (window.innerWidth > 1200 || isShown === true)
            commit('setSidebarDisplay', true);
        else commit('setSidebarDisplay', false);
    },
};
const mutations = {
    setSidebarDisplay(state, isShown) {
        state.sidebarDisplay = isShown;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};