<template>
    <section>
        <div class="test-div">
        <h1>Signup</h1>
        <form @submit.prevent="testFunction">
          <div class="form-row">
            <label>Full Name:-</label>
            <input type="text" v-model="full_name" />
          </div>
          <div class="form-row">
            <label>Email:-</label>
            <input type="email" v-model="email" />
          </div>
          <div class="form-row">
            <label>Country Code:-</label>
            <select v-model="country_code">
              <option value="+1">+1 United States and Canada</option>
            </select>
          </div>
          <div class="form-row">
            <label>Phone:-</label>
            <input type="text" v-model="phone" />
          </div>
          <div class="form-row">
            <label>Password:- </label>
            <input type="password" v-model="password" />
          </div>
          <div class="form-row">
            <label>Confirm Password:- </label>
            <input type="password" v-model="confirm_password" />
          </div>
          <div class="form-row">
            <label>City:-</label>
            <select v-model="city">
              <option value="NYC-USA">New York City, U.S.A</option>
            </select>
          </div>
          <div class="form-row">
            <label>Currency:-</label>
            <select v-model="currency">
              <option value="USD">$ - United States Dollar</option>
            </select>
          </div>
          <div class="form-row">
            <label>Card Number:-</label>
            <input type="text" v-model="card_number" />
          </div>
          <div class="form-row">
            <label>Expiry Month:-</label>
            <select v-model="expiry_month">
              <option value="01">January</option>
            </select>
          </div>
          <div class="form-row">
            <label>Expiry Year:-</label>
            <select v-model="expiry_year">
              <option value="25">25</option>
            </select>
          </div>
          <div class="form-row">
            <label>Name on Card:-</label>
            <input type="text" v-model="name_on_card" />
          </div>
          <button type="submit">Create an Account</button>
        </form>
        <ul>
          <li>
            <router-link to="/signin/">Already have an Account?</router-link>
          </li>
        </ul>
      </div>
    </section>
</template>
<script>
export default {
    name: 'Signup'
}
</script>