<template>
  <section class="app-main">
    <Header />
    <main class="app-main__body">
      <div class="docs-view">
        <!-- <h1 class="docs-view__title-text">Docs</h1> -->
        <docs-sidebar></docs-sidebar>
        <article class="docs-view__view home-view">
          <search-element></search-element>
          <updates-element></updates-element>
          <faqs-element></faqs-element>
          <getting-started></getting-started>
        </article>
      </div>
    </main>
    <DocsFooter />
  </section>
</template>
<script>
import Header from './components/Header.vue';
import DocsSidebar from '../docs/components/DocsSidebar.vue';
import SearchElement from '../docs/components/home/SearchElement.vue';
import UpdatesElement from '../docs/components/home/UpdatesElement.vue';
import FaqsElement from '../docs/components/home/FaqsElement.vue';
import GettingStarted from '../docs/components/home/GettingStarted.vue';
import DocsFooter from '../docs/components/DocsFooter.vue';
export default {
  name: 'Docs',
  components: {
    Header,
    DocsSidebar,
    SearchElement,
    UpdatesElement,
    FaqsElement,
    GettingStarted,
    DocsFooter,
  },
};
</script>
