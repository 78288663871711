<template>
  <section class="app-main">
    <Header />
    <main class="app-main__body pricing__main">
      <div class="pricing__article">
        <h1 class="pricing__h1">
          <span class="pricing__title-text">Pricing (FAQs pending)</span>
          <div class="pricing__price-tag">
            <SpriteIcons :svgClasses="['pricing__tag-icon']" :iconLabel="'icon-price-tag'"/>
            <span class="pricing__price-text">$999/yr</span>
          </div>
        </h1>
        <p class="pricing__text-content">         
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis purus urna, ut volutpat erat venenatis nec. Ut ac rhoncus sem, sit amet maximus tellus. Suspendisse maximus euismod malesuada. Donec porta nulla felis, in aliquam sapien pretium in. Suspendisse mi nisl, ultrices quis turpis nec, scelerisque suscipit mauris. Donec semper lacus metus, ut iaculis quam viverra vel. Vivamus nec egestas leo. Sed non mollis arcu. Etiam vel urna interdum, convallis tellus id, ultricies dolor. Nunc quis magna urna. Integer at venenatis diam, vitae egestas purus. Curabitur finibus turpis eget nisl convallis consequat. Aenean ultricies tincidunt lectus. Donec vel imperdiet mauris, vitae pharetra velit. Phasellus diam mi, rutrum feugiat augue ut, consequat lobortis turpis. Aliquam suscipit maximus odio, quis gravida arcu feugiat a. Maecenas dignissim ornare erat eget sagittis. Curabitur bibendum, est nec vulputate vestibulum, ligula mauris tempus libero, nec convallis odio velit quis eros. Mauris non elit sit amet urna. 
          
        </p>
        <button coa="primary" class="pricing__coa">Create an Account &rarr;</button>
      </div>    
    </main>
    <Footer />
  </section>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import SpriteIcons from '../static/SpriteIcons.vue';
export default {
  name: 'Pricing',
  components: { Header, Footer, SpriteIcons },
  props: {
    svgClasses: Array,
    iconLabels: String
  },
  methods: {
    testFunction(){
      console.log(this.full_name, this.email);
    }
  }
};
</script>
