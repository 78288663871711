<template>
  <div class="docs__docs-footer">
    <footer class="app-main__footer docs-footer">
      <div class="docs-footer__buttons-bar">
        <a
          v-if="this.getSidebarDisplay() === false"
          class="docs-footer__button-item"
          id="docs-footer__browse-button"
          @click="showDocsSidebar"
          href="#"
        >
          <sprite-icons
            :svgClasses="['svg']"
            :iconLabel="'icon-compass2'"
          ></sprite-icons>
          <span>Browse</span>
        </a>
        <a
          v-else
          class="docs-footer__button-item"
          id="docs-footer__browse-button"
          @click="closeDocsSidebar"
          href="#"
        >
          <sprite-icons
            :svgClasses="['svg']"
            :iconLabel="'icon-cross'"
          ></sprite-icons>
          <span>Close</span>
        </a>
        <a class="docs-footer__button-item" href="#">
          <sprite-icons
            :svgClasses="['svg']"
            :iconLabel="'icon-search'"
          ></sprite-icons>
          <span>Search</span>
        </a>
        <router-link class="docs-footer__button-item" to="/contact/">
          <sprite-icons
            :svgClasses="['svg']"
            :iconLabel="'icon-envelop'"
          ></sprite-icons>
          <span>Contact</span>
        </router-link>
      </div>
    </footer>
  </div>
  <div class="docs__default-footer">
    <Footer />
  </div>
</template>
<script>
import Footer from '../../pages/components/Footer.vue';
import SpriteIcons from '../../static/SpriteIcons.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'DocsFooter',
  components: {
    Footer,
    SpriteIcons,
  },
  data() {
    return {
      screenWidth: '',
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  },
  methods: {
    ...mapGetters(['getSidebarDisplay']),
    ...mapActions(['toggleDocsSidebarDisplay']),
    showDocsSidebar(e) {
      e.preventDefault();
      if (this.screenWidth < 1200) {
        this.toggleDocsSidebarDisplay(true);
      }
    },
    closeDocsSidebar() {
      this.toggleDocsSidebarDisplay(false);
    },
  },
};
</script>