import { createStore } from 'vuex';
import about from './modules/pages/about.js'; // Pages app - About Page Store
import docs from './modules/docs/docs.js'; // Docs app - Main Docs Store

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        about,
        docs,
    },
});