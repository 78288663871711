<template>
  <section class="app-main">
    <Header />
    <main class="app-main__body accounts-main">
        <div class="accounts-main__signin-content">
          <div class="signin-content__signin-greetings">
            <h1 class="signin-content__signin-h1 margin-bottom-small">Welcome Back, Signin to get started! </h1>
            <p class="signin-content__signin-text justify-aligned-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis consequat posuere. Integer pretium felis magna, ac suscipit nunc condimentum quis. Fusce vehicula sodales maximus. Sed eget nisl vel orci hendrerit pretium vel ut ipsum. Aliquam pharetra ex at aliquam egestas. Mauris et tincidunt augue, non pretium odio. Vivamus vel malesuada dui. Duis condimentum elementum est ut condimentum. Suspendisse suscipit, libero et dictum facilisis, ante massa semper magna, vitae pretium mi risus id sapien. Duis semper ac lorem ut hendrerit. Vivamus sem eros, fermentum et nulla in, porttitor venenatis purus. Ut bibendum in odio et finibus. 
            </p>
          </div>
          <div class="accounts-main__form-holder">
          
          <form class="form form-style-1">
            <h2>Signin</h2>
            <div class="form-row">
              <label>Email:-</label>
              <input type="email" placeholder="Your Registered Email" name="email" />
            </div>
            <div class="form-row">
              <label> Password:- </label>
              <input type="password" placeholder="Your Password" name="password" />
            </div>
            <div class="form-row">
              <button coa="primary" type="submit">Sign in</button>
            </div>
            <div class="form-row">
              <ul class="display-flex-justify-space-between-align-center accounts-main__form-links">
                <li>
                  <router-link class="form-link" to="/pricing/">
                    <SpriteIcons :svgClasses="[]" :iconLabel="'icon-user-plus'"/>
                    <span>Create an Account</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="form-link" to="/forgot-password/">
                    <SpriteIcons :svgClasses="[]" :iconLabel="'icon-question'"/>
                    <span>Forgot Password?</span>
                  </router-link>
                </li>
              </ul>
            </div>            
          </form>
         
        </div> 
        </div>
             
    </main>
    <Footer />
  </section>  
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import SpriteIcons from '../static/SpriteIcons.vue';
export default {
  name: 'Signin',
  components: {
    Header, Footer, SpriteIcons
  }
};
</script>
