<template>
  <!-- Basic Template
  <section class="app-main">
    <Header />
    <main class="app-main__body"></main>
    <Footer />
  </section>
  -->
  <section class="app-main">
    <Header />
    <main class="app-main__body">
      <article class="app-main__article app-home__article">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          augue massa. </span
        ><br />
        <button>Get Started &rarr;</button>
      </article>
    </main>

    <Footer />
  </section>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'Home',
  components: { Header, Footer },
};
</script>
