<template>
  <div style="width: 720px; margin: auto">
    <h2>Get request</h2>
    <br />
    <p>GET Call response:- {{ get_api_call_message }}</p>
    <br />
    <button @click="performGetRequest">Perform GET request</button><br /><br />
    <h2>POST request</h2>
    <p>POST Call response:- {{ post_api_call_message }}</p>
    <br />
    <p>GET Call CSRF_TOKEN:- {{ get_api_call_csrftoken }}</p>
    <p>
      POST Output:-
      {{ post_api_call_output }}
    </p>
    <br />
    <form @submit.prevent="performPostRequest">
      <input type="text" v-model="input" placeholder="Enter a message" />
      <button type="submit">Perform POST call</button>
    </form>
  </div>
</template>
<script>
export default {
  name: 'TestAPICalls',
  data() {
    return {
      get_api_call_message: '',
      get_api_call_csrftoken: '',
      post_api_call_message: '',
      post_api_call_output: '',
    };
  },
  created() {
    this.getCsrfToken();
  },
  methods: {
    async performGetRequest() {
      const response = await fetch('http://app.acnoid.com/testapicall');
      const data = await response.json();
      this.get_api_call_message = data['message'].toString();
    },
    async getCsrfToken() {
      const response = await fetch('http://127.0.0.1:8000/getcsrftoken');
      const data = await response.json();
      const csrf_token = data['token'].toString();
      this.get_api_call_csrftoken = csrf_token;
      document.cookie = 'csrf_token=' + csrf_token;
      return csrf_token;
    },
    async performPostRequest() {
      const formData = {
        csrfmiddlewaretoken: this.get_api_call_csrftoken,
        input: this.input,
      };
      const postRequestData = {
        method: 'POST',
        headers: {
          'Content-Type': 'appilcation/json',
        },
        body: JSON.stringify(formData),
      };

      console.log(postRequestData);
      const response = await fetch(
        'http://127.0.0.1:8080/testapicall',
        postRequestData
      );
      const data = await response.json();
      this.post_api_call_message = data['message'].toString();
      this.post_api_call_output = data['response'].toString();
    },
  },
};
</script>