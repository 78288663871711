<template>
    <ul class="main-content__sidebar-list">
        <li v-for="link in sidebar_links" :section="link.section" :key="link.label" class="main-content__sidebar-list-item">
        
                <div class="siderbar-list-item__content">
                    <sprite-icons :svgClasses="['siderbar-list-item__content--svg']" :iconLabel="link.icon"></sprite-icons>
                    <span class="siderbar-list-item__content--span">{{link.label}}</span>
                </div>

        </li>       
    </ul>
</template>
<script>
import SpriteIcons from '../../static/SpriteIcons.vue';
import {mapActions} from 'vuex';
export default {
    name: 'AboutSidebar',
    data(){
        return {
            sidebar_links: [
                {section:'section1',label: 'What is Sample text?',icon: 'icon-office'},
                {section:'section2',label: 'How does it work?',icon: 'icon-office'},
                {section:'section3',label: 'What is it solving?',icon: 'icon-office'},
                {section:'section4',label: 'How does it benefit you?',icon: 'icon-office'}
            ]
        }
    },
    components: {
        SpriteIcons
    },
    mounted(){
        const section_links = document.querySelectorAll('.main-content__sidebar-list-item');
        section_links.forEach(link => {
            link.addEventListener('click', e => {
                e.preventDefault();
                const section = link.attributes[0].value;
                this.showSection(section);
            });
        });
    },
    methods: {
        ...mapActions(['showSection']),        
    }
}
</script>