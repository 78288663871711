<template>
  <div class="test-div">
    <h1>Forgot Password</h1>
    <form>
      <div class="form-row">
        <label> Email:- </label>
        <input type="email" name="email" />
      </div>
      <div class="form-row">
        <label>Country Code:-</label>
        <select name="country_code">
          <option value="+1">+1 United States and Canada</option>
        </select>
      </div>
      <div class="form-row">
        <label>Phone:-</label>
        <input type="text" name="phone" />
      </div>
      <a href="#">Use Phone</a><br />
      <router-link to="/verify-otp/">
        <button type="submit">Proceed</button>
      </router-link>
    </form>
    <ul>
      <li>
        <router-link to="/signin/">Signin</router-link>
      </li>
      <li>
        <router-link to="/pricing/">Create an Account</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ForgotPassword',
};
</script>
