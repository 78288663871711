<template>
  <div class="docs-view__links-list">
    <router-link to="/">
      <h2 class="links-list__h2">
        <span>{{ linkListHeading }}</span>
        <sprite-icons :svgClasses="[]" :iconLabel="'icon-new-tab'" />
      </h2>
    </router-link>
    <ul class="links-list__ul">
      <li class="links-list__li" v-for="link in linkset" :key="link.target">
        <router-link :to="link.target">
          <div class="links-list__li-content">
            <sprite-icons
              :svgClasses="[]"
              :iconLabel="listItemIcon"
            ></sprite-icons>
            <span>{{ link.link_text }}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import SpriteIcons from '../../../static/SpriteIcons.vue';

export default {
  name: 'LinksList',
  components: { SpriteIcons },
  props: ['linkListHeading', 'listItemIcon', 'linkset'],
};
</script>