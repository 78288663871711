<template>
  <div class="test-div">
    <h1>Verify OTP</h1>
    <form>
      <div class="form-row">
        <label> Phone OTP:- </label>
        <input type="text" name="phone_otp" />
      </div>
      <div class="form-row">
        <label> Email OTP:- </label>
        <input type="text" name="email_otp" />
      </div>
      <router-link to="/reset-password/">
        <button>Reset Password</button>
      </router-link>
    </form>
  </div>
</template>
<script>
export default {
  name: 'VerifyOTP',
};
</script>
