<template>
  <div class="docs-view__getting-started-element">
    <links-list
      :linkListHeading="'Getting Started'"
      :listItemIcon="'icon-rocket'"
      :linkset="getLinkset()"
    ></links-list>
  </div>
</template>
<script>
// import SpriteIcons from '../../../static/SpriteIcons.vue';
import LinksList from './LinksList.vue';
export default {
  name: 'GettingStarted',
  components: { LinksList },
  props: ['linkListHeading', 'listItemIcon', 'linkset'],
  methods: {
    getLinkset() {
      return [
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
        {
          target: '/',
          link_text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas congue dui pellentesque.',
        },
      ];
    },
  },
};
</script>