<template>
  <aside v-if="this.getSidebarDisplay() === true" class="docs-view__sidebar">
    <ul class="sidebar__links-list">
      <li class="sidebar__link-item">
        <div class="dropdown-list">
          <div class="dropdown-list__label">
            <span class="dropdown-list__label--text">Label </span>
            <span class="dropdown-list__label--arrow">&#9662;</span>
          </div>
          <div class="dropdown-list__list-item dropdown-list__list-item--hide">
            List item
          </div>
        </div>
      </li>
    </ul>
  </aside>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'DocsSidebar',
  mounted() {
    window.addEventListener('resize', this.toggleDocsSidebarDisplay(false));
    this.showDocsSidebar = this.getSidebarDisplay();
  },
  methods: {
    ...mapGetters(['getSidebarDisplay']),
    ...mapActions(['windowsResizeListener', 'toggleDocsSidebarDisplay']),
  },
};
</script>