<template>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-office'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-office"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-cart'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-cart"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-envelop'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-envelop"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-books'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-books"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-user-tie'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-user-tie"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-twitter'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-twitter"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-menu'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-menu"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-price-tag'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-price-tag"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-user-plus'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-user-plus"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-question'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-question"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-search'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-search"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-new-tab'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-new-tab"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-magic-wand'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-magic-wand"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-rocket'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-rocket"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-compass2'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-compass2"></use>
  </svg>
  <svg :class="svgClasses.join(' ')" v-if="iconLabel === 'icon-cross'">
    <use xlink:href="../../svg/icomoon/sprite.svg#icon-cross"></use>
  </svg>
</template>
<script>
export default {
  name: 'SpriteIcons',
  props: {
    iconLabel: String,
    svgClasses: Array,
  },
};
</script>