<template>
  <section class="app-main">
    <Header />
    <main class="app-main__body contact-main">
      <div class="contact-main__text-content">
        <h1 class="contact-main__h1">Contact our Team</h1>
        <p class="contact-main__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis laoreet diam ac pharetra. Morbi vitae sem dolor. Donec mauris odio, vehicula a nibh a, convallis interdum diam. Etiam scelerisque purus suscipit dolor pharetra, consequat suscipit orci porta. Cras ultrices ultrices sagittis. Phasellus eu diam at metus sollicitudin dictum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut vel erat tempus, sodales lectus iaculis, semper nunc. Ut in metus eleifend, sodales lorem vel, vulputate ex. Integer egestas velit ut orci elementum, non eleifend risus placerat. Suspendisse ultrices non tortor eu finibus. Vivamus enim dui, feugiat.
        </p>
      </div>      
      <div class="contact-main__form-holder">
        <form action="" @submit.prevent="onSubmit" class="form contact-main__contact-form form-style-1">
          <h2 class="contact-main__h2">Submit your Message</h2>
          <div class="form-row">
            <label for="name" class="form-label">Your Name:-</label>
            <input id="name" type="text" v-model="full_name" placeholder="Your full name"/>
          </div>
          <div class="form-row">
            <label for="email" class="form-label">Your Email:-</label>
            <input id="email" type="email" v-model="email" placeholder="Your Email"/>
          </div>
          <div class="form-row">
            <label for="message" class="form-label">Your Message:-</label>
            <textarea id="message" placeholder="Enter your message here" v-model="message" class="form-text-area"></textarea>
          </div>
          <div class="form-row">
            <button coa="primary" type="submit">Submit Request</button>
          </div>
        </form>
      </div>      
    </main>
    <Footer />
  </section>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'Contact',
  components: { Header, Footer },
  methods: {
    onSubmit(){
      const form_content = {
        full_name: this.full_name,
        email: this.email,
        message: this.message
      };
      console.log(form_content);
    }
  }
};
</script>
