import { createRouter, createWebHistory } from 'vue-router';
//Pages
import Home from '../pages/Home.vue';
import About from '../pages/About.vue';
import Pricing from '../pages/Pricing.vue';
import Docs from '../pages/Docs.vue';
import Contact from '../pages/Contact.vue';
import Signin from '../pages/Signin.vue';

//Accounts
import Signup from '../accounts/Signup.vue';
import ForgotPassword from '../accounts/ForgotPassword.vue';
import VerifyOTP from '../accounts/VerifyOTP.vue';
import ResetPassword from '../accounts/ResetPassword.vue';

import TestAPICalls from '../pages/TestAPICalls.vue';
const routes = [
    //Pages
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Acnoid - Home',
        },
    },
    {
        path: '/about/',
        name: 'About',
        component: About,
        meta: {
            title: 'Acnoid - About',
        },
    },
    {
        path: '/pricing/',
        name: 'Pricing',
        component: Pricing,
        meta: {
            title: 'Acnoid - Pricing',
        },
    },
    {
        path: '/docs/',
        name: 'Docs',
        component: Docs,
        meta: {
            title: 'Acnoid - Docs',
        },
    },
    {
        path: '/contact/',
        name: 'Contact',
        component: Contact,
        meta: {
            title: 'Acnoid - Contact',
        },
    },
    {
        path: '/signin/',
        name: 'Signin',
        component: Signin,
        meta: {
            title: 'Acnoid - Signin',
        },
    },
    //Accounts
    {
        path: '/signup/',
        name: 'Signup',
        component: Signup,
        meta: {
            title: 'Acnoid - Signup',
        },
    },

    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            title: 'Acnoid - Forgot Password',
        },
    },
    {
        path: '/verify-otp',
        name: 'VerifyOTP',
        component: VerifyOTP,
        meta: {
            title: 'Acnoid - Verify OTP',
        },
    },
    {
        path: '/reset-password/',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: 'Acnoid - Reset Password',
        },
    },

    {
        path: '/testapicall/',
        name: 'TestAPICalls',
        component: TestAPICalls,
        meta: {
            title: 'Acnoid - Test API Calls',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Adding Custom page titles to each views
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
});

export default router;