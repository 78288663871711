<template>
  <footer class="app-main__footer">
    <div class="footer__left-side">
      <p class="footer__copyright-text">
        Copyright &copy; {{ getCurrentYear() }} Sample Text LLC !
        <router-link to="/testapicall/">Test API Calls</router-link>
      </p>
      `
    </div>
    <div class="footer__right-side">
      <ul class="footer__links-list">
        <li v-for="item in links_list" :key="item" class="footer__links-item">
          <router-link :to="item.path">
            <sprite-icons
              :svgClasses="['footer__links-icon']"
              :iconLabel="item.icon"
            ></sprite-icons>
          </router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
import SpriteIcons from '../../static/SpriteIcons.vue';
export default {
  name: 'Footer',
  components: { SpriteIcons },
  data() {
    return {
      links_list: [{ path: '/about/', label: 'About', icon: 'icon-twitter' }],
      api_text: '',
    };
  },
  mounted() {
    this.testAPICall();
  },

  methods: {
    getCurrentYear() {
      const time = new Date();
      const year = time.getFullYear();
      return year;
    },
    async testAPICall() {
      const response = await fetch('http://app.acnoid.com/testapicall');
      const data = await response.json();

      this.api_text = data['message'].toString();
    },
  },
};
</script>
