<template>
  <div class="test-div">
    <h1>Reset Password</h1>
    <div class="form-row">
      <label> New Password:- </label>
      <input type="password" name="new_password" />
    </div>
    <div class="form-row">
      <label> Confirm Password:- </label>
      <input type="password" name="confirm_password" />
    </div>
    <!--<router-link to="/reset-password/">-->
    <button>Reset Password</button>
    <!--</router-link>-->
  </div>
</template>
<script>
export default {
  name: 'ResetPassword',
};
</script>
