<template>
  <header class="app-main__header">
    <div class="header__logo-wrapper">
      <router-link to="/">
        <div class="header__logo-content">
          <img class="header__logo-image" src="#" />
          <span class="header__logo-text">Sample Text</span>
        </div>
      </router-link>
    </div>
    <label class="header__hamburger-button" for="hamburger-button">
      <sprite-icons :svgClasses="['header__hamburger-button']" :iconLabel="'icon-menu'"></sprite-icons>
    </label>
    <input
      class="header__hamburger-checkbox"
      id="hamburger-button"
      type="checkbox"
    />
    <ul class="header__links-list">
      <li
        v-for="item in links_list"
        :key="item.label"
        class="header__links-item"
      >
        <router-link :to="item.path">
          <div class="header__links-item-content">
            <sprite-icons :svgClasses="['links-item-content__svg']" :iconLabel="item.icon"></sprite-icons>          
            <span class="links-item-content__span">{{ item.label }}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </header>
</template>
<script>
import SpriteIcons from '../../static/SpriteIcons.vue';
export default {
  name: 'Header',
  components: {SpriteIcons},
  props: {
    svgClasses: Array, 
    iconLabel: String
  },
  data() {
    return {
      links_list: [
        { path: '/about/', label: 'About', icon: 'icon-office' },
        { path: '/pricing/', label: 'Pricing', icon: 'icon-cart' },
        { path: '/contact/', label: 'Contact', icon: 'icon-envelop' },
        { path: '/docs/', label: 'Docs', icon: 'icon-books' },
        { path: '/signin/', label: 'Signin', icon: 'icon-user-tie' },
      ],
    };
  },
};
</script>
