<template>
  <section class="app-main">
    <Header />
    <main class="app-main__body about__main">
      <div class="about__main-content">
        <div class="main-content__sidebar">
          <about-sidebar></about-sidebar>
        </div>
        <div class="main-content__article">
          <div class="article__view" section="section1">
            <div class="article__body">
              <h1 class="article__h2">What is Sample Text?</h1>
              <p class="article__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a
                velit at augue aliquet sollicitudin. Vivamus suscipit rutrum
                diam luctus pharetra. Vestibulum egestas justo eget porttitor
                mollis. Etiam viverra est lacus, sit amet facilisis dui faucibus
                sollicitudin. Integer interdum porta ipsum non ullamcorper.
                Aliquam lobortis vel dui non varius. Curabitur egestas vel
                tellus sed euismod. Nullam at suscipit lorem. In euismod sem
                mauris, vitae fermentum magna varius nec. Mauris nec dui auctor,
                convallis purus a, fermentum odio. Suspendisse fringilla ante
                sed venenatis imperdiet. Sed pulvinar suscipit vestibulum.
                Phasellus sit amet facilisis augue.Proin tincidunt ex est, nec
                mattis lorem auctor non. Praesent est massa, convallis in
                convallis a, lobortis eu dolor. Donec metus massa, fermentum vel
                lectus in, gravida volutpat mi. Quisque eget neque venenatis
                nisi condimentum bibendum vitae id nisi. Curabitur condimentum
                diam in consectetur pellentesque. In rhoncus, velit sed
                porttitor feugiat, urna orci bibendum nisl, sed porttitor felis
                enim vitae metus. Nunc hendrerit, eros ut sollicitudin
                malesuada, nulla libero pharetra dolor, sed feugiat augue sapien
                dictum elit.
              </p>
            </div>
          </div>
          <div class="article__view" section="section2">
            <div class="article__body">
              <h1 class="article__h2">How does it work?</h1>
              <p class="article__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a
                velit at augue aliquet sollicitudin. Vivamus suscipit rutrum
                diam luctus pharetra. Vestibulum egestas justo eget porttitor
                mollis. Etiam viverra est lacus, sit amet facilisis dui faucibus
                sollicitudin. Integer interdum porta ipsum non ullamcorper.
                Aliquam lobortis vel dui non varius. Curabitur egestas vel
                tellus sed euismod. Nullam at suscipit lorem. In euismod sem
                mauris, vitae fermentum magna varius nec. Mauris nec dui auctor,
                convallis purus a, fermentum odio. Suspendisse fringilla ante
                sed venenatis imperdiet. Sed pulvinar suscipit vestibulum.
                Phasellus sit amet facilisis augue.Proin tincidunt ex est, nec
                mattis lorem auctor non. Praesent est massa, convallis in
                convallis a, lobortis eu dolor. Donec metus massa, fermentum vel
                lectus in, gravida volutpat mi. Quisque eget neque venenatis
                nisi condimentum bibendum vitae id nisi. Curabitur condimentum
                diam in consectetur pellentesque. In rhoncus, velit sed
                porttitor feugiat, urna orci bibendum nisl, sed porttitor felis
                enim vitae metus. Nunc hendrerit, eros ut sollicitudin
                malesuada, nulla libero pharetra dolor, sed feugiat augue sapien
                dictum elit.
              </p>
            </div>
          </div>
          <div class="article__view" section="section3">
            <div class="article__body">
              <h1 class="article__h2">What is it solving?</h1>
              <p class="article__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a
                velit at augue aliquet sollicitudin. Vivamus suscipit rutrum
                diam luctus pharetra. Vestibulum egestas justo eget porttitor
                mollis. Etiam viverra est lacus, sit amet facilisis dui faucibus
                sollicitudin. Integer interdum porta ipsum non ullamcorper.
                Aliquam lobortis vel dui non varius. Curabitur egestas vel
                tellus sed euismod. Nullam at suscipit lorem. In euismod sem
                mauris, vitae fermentum magna varius nec. Mauris nec dui auctor,
                convallis purus a, fermentum odio. Suspendisse fringilla ante
                sed venenatis imperdiet. Sed pulvinar suscipit vestibulum.
                Phasellus sit amet facilisis augue.Proin tincidunt ex est, nec
                mattis lorem auctor non. Praesent est massa, convallis in
                convallis a, lobortis eu dolor. Donec metus massa, fermentum vel
                lectus in, gravida volutpat mi. Quisque eget neque venenatis
                nisi condimentum bibendum vitae id nisi. Curabitur condimentum
                diam in consectetur pellentesque. In rhoncus, velit sed
                porttitor feugiat, urna orci bibendum nisl, sed porttitor felis
                enim vitae metus. Nunc hendrerit, eros ut sollicitudin
                malesuada, nulla libero pharetra dolor, sed feugiat augue sapien
                dictum elit.
              </p>
            </div>
          </div>
          <div class="article__view" section="section4">
            <div class="article__body">
              <h1 class="article__h2">How does it benefit you?</h1>
              <p class="article__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a
                velit at augue aliquet sollicitudin. Vivamus suscipit rutrum
                diam luctus pharetra. Vestibulum egestas justo eget porttitor
                mollis. Etiam viverra est lacus, sit amet facilisis dui faucibus
                sollicitudin. Integer interdum porta ipsum non ullamcorper.
                Aliquam lobortis vel dui non varius. Curabitur egestas vel
                tellus sed euismod. Nullam at suscipit lorem. In euismod sem
                mauris, vitae fermentum magna varius nec. Mauris nec dui auctor,
                convallis purus a, fermentum odio. Suspendisse fringilla ante
                sed venenatis imperdiet. Sed pulvinar suscipit vestibulum.
                Phasellus sit amet facilisis augue.Proin tincidunt ex est, nec
                mattis lorem auctor non. Praesent est massa, convallis in
                convallis a, lobortis eu dolor. Donec metus massa, fermentum vel
                lectus in, gravida volutpat mi. Quisque eget neque venenatis
                nisi condimentum bibendum vitae id nisi. Curabitur condimentum
                diam in consectetur pellentesque. In rhoncus, velit sed
                porttitor feugiat, urna orci bibendum nisl, sed porttitor felis
                enim vitae metus. Nunc hendrerit, eros ut sollicitudin
                malesuada, nulla libero pharetra dolor, sed feugiat augue sapien
                dictum elit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </section>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import AboutSidebar from './components/AboutSidebar.vue';
import { mapActions } from 'vuex';

export default {
  name: 'About',
  components: { Header, Footer, AboutSidebar },
  props: ['sectionsList'],
  mounted() {
    this.showSection('section1');
  },
  methods: {
    ...mapActions(['showSection']),
  },
  /* s */
};
</script>
