<template>
  <div class="docs-view__search-element">
    <h2 class="search-element__search-title">
      <span>Search your</span><span> questions here &darr;</span>
    </h2>
    <form action="" class="form search-element__search-form">
      <input
        type="text"
        placeholder="Enter your question"
        class="search-form__input-field"
      />
      <button type="submit" class="search-form__search-button">
        <sprite-icons :svgClasses="[]" :iconLabel="'icon-search'" />
      </button>
    </form>
  </div>
</template>
<script>
import SpriteIcons from '../../../static/SpriteIcons.vue';
export default {
  name: 'SearchElement',
  components: { SpriteIcons },
};
</script>