const state = {
    activeSection: 'section1',
};

const getters = {
    getAboutPageActiveSection(state) {
        return state.activeSection;
    },
};

const actions = {
    aboutPageShowTargetSection() {
        const sectionsQuery = document.querySelectorAll('.article__view');
        sectionsQuery.forEach(section => {
            if (section.attributes[1].value != state.activeSection)
                section.style.display = 'none';
            else section.style.display = 'flex';
        });
    },
    showSection({ commit }, section) {
        commit('setAboutPageActiveSection', section);
        actions.aboutPageShowTargetSection();
    },
};

const mutations = {
    setAboutPageActiveSection(state, section) {
        state.activeSection = section;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};